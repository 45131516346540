<template>
  	<IpBundlEdit />
</template>

<script>

// @ is an alias to /src
import IpBundlEdit from "@/components/IpBundle/IpBundleForm.vue";

export default {
  name: "IpBundlEdits",
  components: {
	  IpBundlEdit
  },
};
</script>
