<template>
  	<AddIp />
</template>

<script>

// @ is an alias to /src
import AddIp from "@/components/IpLists/IpForm.vue";

export default {
  name: "AddIps",
  components: {
    AddIp,
  },
};
</script>
