<template>
   <Dashboard msg="Welcome to Dashboard ( Home )" v-else/>
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/Dashboard.vue";
export default {
  name: "Home",
  components: {
    Dashboard
  },
  data() {
    return {
    }
  },
  created(){
  
  }
};
</script>
