<template>
  <Addservers />
</template>

<script>
// @ is an alias to /src
import Addservers from "@/components/ServerForm.vue";

export default {
  name: "Addserverss",
  components: {
    Addservers,
  },
};
</script>
