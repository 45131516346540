import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  	isLogin : false,
  	user : null,
    panel: null,
    panelData: {}
  },
  getters: {
    getLoginStatus: state => {
      return state.isLogin;
    },
    getUser: state => {
      return state.user;
    },
    getPanel: state => {
      return state.panel;
    },
    getPanelData: state => {
      return state.panelData;
    }
  },
  mutations: {
  	channgeIsLoginState(state, payload){
		  state.isLogin = payload;
	  },
    channgeUserState(state, payload){
      state.user = payload;
    },
    changePanel(state, payload){
      state.panel = payload;
    },
    changePanelData(state, payload){
      state.panelData = payload;
    }
  },
  actions: {
  	changLoginStatus(context, payload){
		  context.commit('channgeIsLoginState', payload);
	  },
    setUser(context, payload){
      context.commit('channgeUserState', payload);
    },
    setPanel(context, payload){
      context.commit('changePanel', payload);
    },
    setPanelData(context, payload){
      context.commit('changePanelData', payload);
    }
  },
  modules: {},
});
