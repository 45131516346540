<template>
  <aside class="main-sidebar">
    <section class="sidebar">
      <div class="user-panel">
        <div class="pull-left image">
          <img
            src="/assets/img/defaultProPic.png"
            class="img-circle"
            alt="User Image"
          />
        </div>
        <div class="pull-left info">
          <p>{{ userData.reseller.full_name }}</p>
          <a href="#"> <i class="fa fa-circle text-success"></i> 在线 </a>
        </div>
      </div>
      <ul class="sidebar-menu">
        <li>
          <router-link :to="{ name: 'AddOfficialAccounts' }">
            <span>添加正式帐号</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'PinLists' }">
            <span>查看正式帐号</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AddTestAccounts' }">
            <span>添加测试帐号</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'FreePinLists' }">
            <span>查看测试帐号</span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'IpLists' }">
            <span>服务器列表</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'IpBundleLists' }">
            <span>服务器组列表</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ServerLists' }">
            <span>VPN 服务器列表</span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'Home' }">
            <span>管理员主页</span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'AddRatePlans' }">
            <span>添加新套餐</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'RatePlans' }">
            <span>套餐列表</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AddAnnouncements' }">
            <span>公告</span>
          </router-link>
        </li>
        <hr />
        <li>
          <router-link :to="{ name: 'PinSubscriptionReportView' }">
            <span>帐号报告</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'HourlySubscriptionReportView' }">
            <span>每小时帐号状态</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'OnlineTransactionReportView' }">
            <span>在线收入情况</span>
          </router-link>
        </li>
        <hr />
        <li>
          <router-link :to="{ name: 'RadiusAuthList' }">
            <span>认证服务器</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AddConfigSettings' }">
            <span>应用程式配置</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AddPanelSettings' }">
            <span>面板</span>
          </router-link>
        </li>
      </ul>
    </section>
  </aside>
</template>
<script>
import axios from "axios";
export default {
  name: "LeftSideBar",
  data() {
    return {
      userData: {},
      setting_access: false,
      reportsActive: false,
      rateActive: false,
      subscriptionMenuActive: false,
      settingsActive: false,
      DashboardActive: false,
      AnnouncementActive: false,
      serverActive: false,
      ipActive: false,
    };
  },
  created() {
    this.activeInactiveSideBar();
    this.setting_access = this.com_verifyAllowOrNotSetting();
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    var userData = JSON.parse(decodedString);
    this.userData = userData;
    //console.log(this.userData);
  },
  methods: {
    activeInactiveSideBar() {
      let routeName = this.$route.name;
      if (
        routeName == "PinSubscriptionReportView" ||
        routeName == "ResellerTransactionReportView" ||
        routeName == "PaymentReportView" ||
        routeName == "IndividualSoldPinReportView" ||
        routeName == "HourlySubscriptionReportView" ||
        routeName == "OnlineTransactionReportView"
      ) {
        this.reportsActive = true;
      }

      if (
        routeName == "AddRatePlans" ||
        routeName == "RatePlans" ||
        routeName == "EditRatePlans" ||
        routeName == "SaleingRatePlans" ||
        routeName == "EditSaleRates"
      ) {
        this.rateActive = true;
      }

      if (
        routeName == "PinLists" ||
        routeName == "AddOfficialAccounts" ||
        routeName == "AddTestAccounts" ||
        routeName == "FreePinLists"
      ) {
        this.subscriptionMenuActive = true;
      }

      if (
        routeName == "AddPanelSettings" ||
        routeName == "AddConfigSetting" ||
        routeName == "RadiusAuthList" ||
        routeName == "AddRadiusAuth" ||
        routeName == "AddRadiusAuth"
      ) {
        this.settingsActive = true;
      }

      if (routeName == "Home") {
        this.DashboardActive = true;
      }
      if (routeName == "AddAnnouncements") {
        this.AnnouncementActive = true;
      }
      if (routeName == "Addservers" || routeName == "ServerLists") {
        this.serverActive = true;
      }
      if (
        routeName == "IpLists" ||
        routeName == "IpBundleLists" ||
        routeName == "IpBundleAdd" ||
        routeName == "AddIp"
      ) {
        this.ipActive = true;
      }
    },
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
