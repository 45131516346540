<template>
  <FreePinList />
</template>

<script>
// @ is an alias to /src
import FreePinList from "@/components/FreePinList.vue";

export default {
  name: "FreePinLists",
  components: {
    FreePinList,
  },
};
</script>
