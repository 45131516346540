<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-10">
          <h1>服务器组列表</h1>
        </div>
        <div class="col-xs-2">
          <router-link :to="{ name: 'IpBundleAdd' }" class="btn btn-sm btn-add">
            <i class="fa fa-plus-square"></i>创建服务器组
          </router-link>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="dopagination(0)">
        <div class="row">
          <div class="col-xs-6 col-sm-3 form-group">
            <input
              placeholder="IP 捆绑名称"
              type="text"
              v-model="name"
              name="name"
              id="name"
              class="form-control input-sm"
              value=""
              autocomplete="off"
            />
          </div>
          <div class="col-xs-6 col-sm-3 form-group" style="margin-bottom: 0">
            <select
              title=""
              name="status"
              id="status"
              class="form-control input-sm select2"
              v-model="status"
              v-select="status"
              @change="dopagination(0)"
            >
              <option value="">过滤器状态</option>
              <option value="1">积极的</option>
              <option value="2">不活跃</option>
            </select>
          </div>
          <div class="col-xs-6 col-sm-3 form-group" style="margin-bottom: 0">
            <select
              title=""
              name="limit"
              id="limit"
              class="form-control input-sm select2"
              @change="dopagination(0)"
              v-model="display_limit"
              v-select="display_limit"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50" selected>50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col-xs-6 col-sm-3 ZRP form-group btnLeft">
            <a
              class="btn btn-sm btn-search"
              id="apply"
              @click="dopagination(0)"
            >
              <i class="fa fa-search"></i> 搜索
            </a>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">
                全部的 : {{ totalData }} 显示中 {{ dispplayFrom }} -
                {{ dispplayTo }}
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">序列号</th>
                      <th class="tdCenter">姓名</th>
                      <th class="tdCenter">IPs</th>
                      <th class="tdCenter">服务器名称</th>
                      <th class="tdCenter">地位</th>
                      <th class="tdCenter">行动</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataCollections" :key="data.id">
                      <td class="tdCenter tdSN">
                        {{ parseInt(sl) + parseInt(index) }}
                      </td>
                      <td class="tdCenter" width="150">
                        <router-link
                          :to="{
                            name: 'IpBundleDtlsLists',
                            params: { id: data.id },
                          }"
                          title="Details"
                        >
                          {{ data.name }}
                        </router-link>
                      </td>
                      <td>
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Double Click To Copy"
                          style="cursor: copy"
                          @dblclick="copyNow('ip_' + index)"
                          v-if="data.ips"
                        >
                          <span class="ips" :id="'ip_' + index">
                            {{ data.ips }}
                          </span>
                        </span>
                      </td>
                      <td>
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Double Click To Copy"
                          style="cursor: copy"
                          @dblclick="copyNow('ipname_' + index)"
                          v-if="data.ips"
                        >
                          <span class="ipname" :id="'ipname_' + index">
                            {{ data.ip_name }}
                          </span>
                        </span>
                      </td>
                      <td class="tdCenter">
                        <span
                          class="label label-success"
                          v-if="data.status == 1"
                          >积极的</span
                        >
                        <span class="label label-danger" v-else>主动性</span>
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{
                            name: 'IpBundleEdit',
                            params: { id: data.id },
                          }"
                          title="Edit"
                        >
                          <i class="ion ion-ios-compose table-edit"></i>
                        </router-link>
                        <a @click="removeData(data)" v-if="!data.ips"
                          ><i class="ion ion-ios-trash table-delete"></i
                        ></a>
                      </td>
                    </tr>
                    <tr v-if="dataCollections.length == 0">
                      <td colspan="6" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="pagination" v-if="last_page > 1">
                <a
                  v-if="prev_page_url"
                  href="#"
                  @click="dopagination(prevPage)"
                  class="next"
                >
                  « Previous
                </a>
                <span v-else class="disabled">« Previous</span>
                <span v-if="last_page > max_display_pagination">
                  <span v-for="index in last_page">
                    <span v-if="index == 1 || index == 2">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                      <span v-if="index == 2 && firstDotted">..</span>
                    </span>
                    <span v-if="randomPageNumber.includes(index)">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                    </span>
                    <span v-if="index == last_page || index == last_page - 1">
                      <span v-if="index == last_page - 1 && lastDotted">
                        ..
                      </span>
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span v-for="index in last_page">
                    <span class="current" v-if="index == current_page">
                      {{ index }}
                    </span>
                    <span v-else>
                      <a href="#" @click="dopagination(index)">{{ index }}</a>
                    </span>
                  </span>
                </span>
                <a
                  v-if="next_page_url"
                  href="#"
                  @click="dopagination(nextPage)"
                  class="next"
                >
                  Next »
                </a>
                <span v-else class="disabled">Next »</span>
              </div>
            </div>
            <div class="box-footer"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "RatePlan",
  data() {
    return {
      dataCollections: [],
      user_data: {},
      name: "",
      ip: "",
      alert_message: "",
      dispplayFrom: 0,
      dispplayTo: 0,
      totalData: 0,
      display_limit: 50,
      status: "",
      sl: 1,

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
    };
  },
  created() {
    this.dopagination(0);

    // Check if the query parameter showAlert is present
    const showAlert = this.$route.query.showAlert;
    if (showAlert) {
      // Set the alert message
      this.alert_message = "Saved successfully!";
    }
  },
  methods: {
    copyNow(id) {
      var range = document.createRange();
      range.selectNode(document.getElementById(id));
      window.getSelection().removeAllRanges(); // clear current selection
      window.getSelection().addRange(range); // to select text
      document.execCommand("copy");
      //window.getSelection().removeAllRanges();// to deselect
      toastr.info(" 链接已复制到剪贴板 !");
    },

    removeData(data) {
      var rootObject = this;
      let msg = "您真的要删除吗?";

      $.confirm({
        title: "确认!",
        content: msg,
        buttons: {
          yes: function () {
            axios
              .delete(`ip-bundles/${data.id}`)
              .then((response) => {
                rootObject.alert_message = "删除成功 !";
                rootObject.dopagination(0);
              })
              .catch((error) => console.error(error));
          },
          no: function () {
            //return;
          },
        },
      });
    },

    dopagination(page_no = "") {
      loader.show();
      var rootObject = this;
      let querystr =
        "name=" +
        this.name +
        "&status=" +
        this.status +
        "&paginate=" +
        this.display_limit;
      if (page_no > 0) {
        querystr += "&page=" + page_no;
      }
      //console.log(querystr);

      axios
        .get("ip-bundles?" + querystr)
        .then(function (response) {
          rootObject.populateData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateData(response) {
      var responseData = response.data;
      //console.log(response);
      this.sl = parseInt(responseData.from);
      this.dataCollections = responseData.data;
      this.totalData = responseData.total;
      this.dispplayFrom = responseData.from;
      this.dispplayTo = responseData.to;
      this.display_limit = responseData.per_page;
      this.paginationGenerate(responseData);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.prev_page_url;
      this.next_page_url = response.next_page_url;
      this.last_page = response.last_page;
      this.current_page = response.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },
  },
  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
