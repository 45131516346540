<template>
  	<ServerLists />
</template>

<script>

// @ is an alias to /src
import ServerLists from "@/components/ServerLists.vue";

export default {
  name: "ServerListss",
  components: {
    ServerLists,
  },
};
</script>
