<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-12">
          <h1 class="pull-left">账目报告</h1>
          <div class="pull-right">
            <a
              href="#"
              @click="downloadNow"
              class="btn"
              style="background-color: #526883; color: white"
              ><i class="fa fa-download"></i> 下载已过滤</a
            >
            &nbsp;
            <a
              href="#"
              class="btn"
              style="background-color: #526883; color: white; display: none"
              ><i class="fa fa-cloud-download" style="font-size: 16px"></i>
              全部下载</a
            >
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="searchReportData">
        <div class="row">
          <div class="col-xs-6 col-sm-2">
            <div class="form-group">
              <filterDropdownOptions />
            </div>
          </div>
          <div class="col-xs-6 col-sm-2 form-group">
            <input
              placeholder="From"
              type="text"
              value=""
              name="starttime"
              id="starttime"
              v-model="starttime"
              autocomplete="off"
              class="form-control input-sm datePicker"
            />
          </div>
          <div class="col-xs-6 col-sm-2 form-group">
            <input
              placeholder="To"
              type="text"
              value=""
              name="stoptime"
              id="stoptime"
              v-model="stoptime"
              class="form-control input-sm datePicker"
              autocomplete="off"
            />
          </div>
          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title=""
              name="type"
              id="type"
              v-model="type"
              v-select="type"
              class="form-control input-sm select2"
              @change="searchReportData"
            >
              <option value="">选择类型</option>
              <option value="1">卖</option>
              <option value="2">返回</option>
            </select>
          </div>

          <div class="col-xs-6 col-sm-2 form-group">
            <input
              placeholder="按帐户 ID 搜索"
              type="text"
              name="login"
              id="login"
              v-model="login"
              class="form-control input-sm"
              value=""
            />
          </div>

          <div
            id="btnSearch"
            class="col-xs-6 col-sm-2 form-group btnLeft"
            style="margin-bottom: 5px"
          >
            <button type="submit" class="btn btn-sm btn-submit">
              <i class="fa fa-search"></i> 搜索
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">全部的 {{ listArr.length }} 找到行</h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">序列号</th>
                      <th class="tdCenter">帐户ID</th>
                      <th class="tdCenter">登录/返回日期</th>
                      <th class="tdCenter">到期日</th>
                      <th class="tdCenter">计划</th>
                      <th class="tdCenter">点头</th>
                      <th class="tdCenter">类型</th>
                      <th class="tdRight">售价</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in listArr" :key="data.id">
                      <td class="tdCenter tdSN">
                        {{ parseInt(++index) }}
                      </td>
                      <td
                        :class="
                          data.type == 2
                            ? ' tdCenter danger'
                            : 'tdCenter success'
                        "
                      >
                        AC1000{{ data.pinlist_id }}
                      </td>
                      <td class="tdCenter">
                        {{ data.creationdate }}
                      </td>
                      <td
                        :class="
                          data.type == 2 ? 'tdCenter text-red' : 'tdCenter'
                        "
                      >
                        {{ data.expire_date }}
                      </td>
                      <td
                        :class="
                          data.type == 2 ? 'tdCenter text-red' : 'tdCenter'
                        "
                      >
                        {{ data.description }}
                      </td>
                      <td
                        :class="
                          data.type == 2 ? 'tdCenter text-red' : 'tdCenter'
                        "
                      >
                        {{ data.no_of_days }}
                      </td>
                      <td class="tdCenter">
                        <span class="label label-success" v-if="data.type == 1">
                          Sale
                        </span>
                        <span class="label label-danger" v-if="data.type == 2">
                          Return
                        </span>
                      </td>
                      <td
                        :class="
                          data.type == 2
                            ? 'tdRight info text-red'
                            : 'tdRight info'
                        "
                      >
                        <span v-if="data.type == 2">-</span>
                        <span>{{ data.reseller_price }}</span>
                      </td>
                    </tr>
                    <tr v-if="listArr.length == 0">
                      <td colspan="10" class="tdCenter" style="padding: 30px 0">
                        <b>没有找到记录</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-middle-small table-bordered">
                <thead>
                  <tr>
                    <th class="tdCenter">总记录数</th>
                    <th class="tdCenter">返回记录</th>
                    <th class="tdCenter">总金额</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="success">{{ listArr.length }}</td>
                    <td class="warning">{{ return_count }}</td>
                    <td class="info">
                      {{ total_last_retail_payment }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import filterDropdownOptions from "@/components/common/filterDropdownOptions.vue";
import axios from "axios";
export default {
  name: "PinSubscriptionReport",
  components: {
    filterDropdownOptions,
  },
  data() {
    return {
      listArr: [],
      userData: {},
      lineTotalAmount: 0,
      type: "",
      id_reseller: "",
      starttime: "",
      stoptime: "",
      is_online: "",
      login: "",
      userlevel: "",
      total_last_retail_payment: 0,
      return_count: 0,
    };
  },
  created() {
    //Get User Level from user store
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    var userData = JSON.parse(decodedString);
    this.userData = userData;
    this.userlevel = userData.user_level;
    this.filterWiseDateRangeSet();
    this.searchReportData();
  },
  methods: {
    downloadNow() {
      loader.show();
      var rootObject = this;

      const form = new FormData();
      form.append("type", this.type);
      form.append("fromDate", this.starttime);
      form.append("toDate", this.stoptime);
      form.append("id_reseller", this.id_reseller);
      form.append("login", this.login);
      form.append("is_online", this.is_online);
      form.append("allow_child", 1);
      form.append("download", 1);
      form.append("short_filter", $("#short_filter").val());

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob",
      };

      axios
        .post("reports/pin-subscription", form, config)
        .then(function (response) {
          //console.log(response);
          const blob = new Blob([response.data], { type: "application/csv" });
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          var prefix = "subscription_report_";
          var file_name = prefix.concat(rootObject.getFormattedTime());
          var ext = ".csv";
          a.download = file_name.concat(ext);
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    searchReportData() {
      loader.show();
      var rootObject = this;

      const form = new FormData();
      form.append("type", this.type);
      form.append("fromDate", this.starttime);
      form.append("toDate", this.stoptime);
      form.append("id_reseller", this.id_reseller);
      form.append("login", this.login);
      form.append("is_online", this.is_online);
      form.append("allow_child", 1);
      form.append("short_filter", $("#short_filter").val());

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("reports/pin-subscription", form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.populateReportData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateReportData(response) {
      var rootObject = this;
      var creationdate = "";
      var no_of_days = 0;
      var total_last_retail_payment = 0;
      var return_count = 0;
      this.listArr = [];
      this.total_last_retail_payment = 0;
      this.return_count = 0;

      var childArr = response.data.data.childArr;
      for (var prop in response.data.data.pinData) {
        if (this.userlevel == response.data.data.pinData[prop]["level"]) {
          response.data.data.pinData[prop]["reseller_price"] =
            response.data.data.pinData[prop]["selling_price"];
        } else {
          response.data.data.pinData[prop]["reseller_price"] =
            response.data.data.pinData[prop][
              "reseller_level_" + (this.userlevel - 1) + "_price"
            ];
        }

        if (response.data.data.pinData[prop]["type"] == 2) {
          return_count++;
          response.data.data.pinData[prop]["expire_date"] = "00-00-00 00:00:00";
          total_last_retail_payment =
            parseFloat(total_last_retail_payment) -
            parseFloat(response.data.data.pinData[prop]["reseller_price"]);
        } else {
          total_last_retail_payment =
            parseFloat(total_last_retail_payment) +
            parseFloat(response.data.data.pinData[prop]["reseller_price"]);
          no_of_days = response.data.data.pinData[prop]["no_of_days"];
          creationdate = response.data.data.pinData[prop]["creationdate"];
          if (no_of_days > 0) {
            response.data.data.pinData[prop][
              "expire_date"
            ] = this.com_modifyDateTime(no_of_days, creationdate);
          } else {
            response.data.data.pinData[prop]["expire_date"] = "";
          }
        }
        this.listArr.push(response.data.data.pinData[prop]);
      }

      this.return_count = return_count;
      this.total_last_retail_payment = total_last_retail_payment.toFixed(2);
      loader.hide();
    },

    filterWiseDateRangeSet() {
      $(".datePicker").removeAttr("readonly", "readonly");
      if ($("#short_filter").val()) {
        this.starttime = "";
        this.stoptime = "";
        $(".datePicker").attr("readonly", "readonly");
      } else {
        var dateRangeArr = this.com_currentDateRange();
        this.starttime = dateRangeArr.startDate;
        this.stoptime = dateRangeArr.endDate;
      }
    },

    getFormattedTime() {
      var today = new Date();
      var y = today.getFullYear();
      var m = today.getMonth() + 1;
      var d = today.getDate();
      return y + "-" + m + "-" + d;
    },
  },
  mounted: function () {
    var rootObject = this;
    $("#starttime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.starttime = $(this).val();
      });

    $("#stoptime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.stoptime = $(this).val();
      });

    $("#short_filter").on("change", function () {
      rootObject.filterWiseDateRangeSet();
      rootObject.searchReportData();
    });

    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
