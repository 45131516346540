<template>
  <RadiusComponent />
</template>

<script>
// @ is an alias to /src
import RadiusComponent from "@/components/RadiusAuth.vue";

export default {
  name: "RadiusAuth",
  components: {
    RadiusComponent,
  },
};
</script>
