<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>安装或修复 VPN 服务器</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> 标记为必填字段</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <form role="form">
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">要求（提供）的信息</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >服务器IP <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="server_ip"
                      v-model="server_ip"
                      id="server_ip"
                      class="form-control input-sm"
                      value=""
                      placeholder="VPN 服务器 服务器IP"
                      type="text"
                      disabled
                    />
                    <label v-if="error_server_ip" class="error" for="name">{{
                      error_server_ip
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >服务器密码<span class="mandatory_horizontal"
                      >*</span
                    ></label
                  >
                  <div class="col-md-8">
                    <input
                      name="server_password"
                      id="server_password"
                      class="form-control input-sm"
                      v-model="server_password"
                      value=""
                      placeholder="服务器密码"
                      type="text"
                    />
                    <label
                      v-if="error_server_password"
                      class="error"
                      for="name"
                      >{{ error_server_password }}</label
                    >
                  </div>
                </div>
                <div class="form-group" v-if="is_openvpn">
                  <label class="col-md-4 control-label"
                    >认证服务器 服务器IP
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="auth_server_ip"
                      v-model="auth_server_ip"
                      id="auth_server_ip"
                      class="form-control input-sm"
                      value=""
                      placeholder="认证服务器 服务器IP(Ex:000.000.000.000)"
                      type="text"
                    />
                    <label
                      v-if="error_auth_server_ip"
                      class="error"
                      for="name"
                      >{{ error_auth_server_ip }}</label
                    >
                  </div>
                </div>
                <div class="form-group" v-if="is_openvpn">
                  <label class="col-md-4 control-label"
                    >服务器密码<span class="mandatory_horizontal"
                      >*</span
                    ></label
                  >
                  <div class="col-md-8">
                    <input
                      name="auth_server_password"
                      id="auth_server_password"
                      class="form-control input-sm"
                      v-model="auth_server_password"
                      value=""
                      placeholder=" 服务器密码"
                      type="text"
                    />
                    <label
                      v-if="error_auth_server_password"
                      class="error"
                      for="name"
                      >{{ error_auth_server_password }}</label
                    >
                  </div>
                </div>

                <div class="form-group" v-if="is_shadowsocks || is_v2ray">
                  <label class="col-md-4 control-label">
                    <span v-if="is_shadowsocks">ShadowSocks</span>
                    <span v-if="is_v2ray">V2Ray</span>
                    端口<span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="v2ray_port"
                      id="v2ray_port"
                      class="form-control input-sm"
                      v-model="v2ray_port"
                      value=""
                      placeholder="端口"
                      type="text"
                    />
                    <label v-if="error_v2ray_port" class="error" for="name">{{
                      error_v2ray_port
                    }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >安装协议<span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <div class="col-md-4 border-left border-right">
                      <div class="form-group">
                        <label class="col-md-9 control-label">Open VPN</label>
                        <div class="col-md-3">
                          <input
                            name="is_openvpn"
                            type="checkbox"
                            v-model="is_openvpn"
                            id="is_openvpn"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 border-left border-right">
                      <div class="form-group">
                        <label class="col-md-9 control-label">WireGuard</label>
                        <div class="col-md-3">
                          <input
                            name="is_wireguard"
                            type="checkbox"
                            v-model="is_wireguard"
                            id="is_wireguard"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 border-left border-right">
                      <div class="form-group">
                        <label class="col-md-9 control-label"
                          >Shadowsocks</label
                        >
                        <div class="col-md-3">
                          <input
                            name="is_shadowsocks"
                            type="checkbox"
                            v-model="is_shadowsocks"
                            id="is_shadowsocks"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 border-left border-right">
                      <div class="form-group">
                        <label class="col-md-9 control-label">V2Ray</label>
                        <div class="col-md-3">
                          <input
                            name="is_v2ray"
                            type="checkbox"
                            v-model="is_v2ray"
                            id="is_v2ray"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <label
                        v-if="error_protocol_selection"
                        class="error"
                        for="name"
                        >{{ error_protocol_selection }}</label
                      >
                    </div>
                  </div>
                </div>

                <div style="margin-top: 25px" class="form-group">
                  <div class="col-md-12 btnCenter">
                    <div v-if="!loading">
                      <a
                        @click="formSubmit"
                        class="btn btn-submit btn-submit-big"
                      >
                        {{ ovpn_status == 3 ? "安装" : "维修" }}
                      </a>
                    </div>
                    <div v-else>
                      <span
                        style="
                          font-weight: 700;
                          font-size: 14px;
                          color: orangered;
                          margin-bottom: 10px;
                        "
                        >Server Provisioning Start. Please Wait.
                        <img :src="loaderGif" alt="Loading" />
                      </span>
                      <div
                        class="alert text-center alert-warning alert-dismissible text-left font__family-montserrat font__size-16 font__weight-light brk-library-rendered rendered show"
                        role="alert"
                        data-brk-library="component__alert"
                      >
                        <i
                          class="start-icon fa fa-info-circle faa-shake animated"
                        ></i>
                        <strong class="font__weight-semibold"
                          >Attention!</strong
                        >
                        Please Don't Reload When Server Provisioning.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">安慰</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <pre
                  :class="output.installation_status == 1 ? 'success' : 'error'"
                >
                  <div class="success" v-if="loading">
                    加工....
                  </div>
                  {{ output.openvpn_log }}
                  {{ output.auth_server_log }}
                  {{ output.wireguard_log }}
                  {{ output.automation_log }}
                  {{ output.shadowsocks_log }}
                </pre>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "VpnInstaller",
  data() {
    return {
      loaderGif: "/assets/img/provisoning.gif",
      value: null,
      id: "",
      server_ip: "",
      server_password: "",
      auth_server_ip: "",
      auth_server_password: "",
      ovpn_status: "",
      output: "",
      loading: false,
      isEditing: false,
      alert_message: "",
      LabelFormName: "Add New",
      v2ray_port: "",

      error_server_password: false,
      error_server_ip: false,
      error_auth_server_ip: false,
      error_auth_server_password: false,
      error_cityLists: false,
      error_v2ray_port: false,

      is_openvpn: false,
      is_wireguard: false,
      is_automation: false,
      is_shadowsocks: false,
      is_v2ray: false,
      error_protocol_selection: false,
    };
  },
  mounted: function () {
    $(".select2").select2();
  },
  created() {
    loader.show();
    var rootObject = this;
    if (this.$route.params.id) {
      rootObject.isEditing = true;
      rootObject.id = this.$route.params.id;
      rootObject.getEditData();
    }
  },
  methods: {
    labelWithCity({ label, city }) {
      return `${label} — [${city}]`;
    },
    getEditData() {
      loader.show();
      let rootObject = this;
      rootObject.LabelFormName = "Edit";
      axios
        .get("vpn-servers/" + rootObject.id)
        .then(function (response) {
          rootObject.server_ip = response.data.ip;
          rootObject.ovpn_status = response.data.ovpn_status;

          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_server_password = false;
          rootObject.error_ip = false;
          rootObject.error_auth_server_ip = false;
          rootObject.error_auth_server_password = false;
          rootObject.error_protocol_selection = false;
          rootObject.error_v2ray_port = false;
        }, 5000);
        return 0;
      }

      var is_openvpn = this.is_openvpn === true ? 1 : 0;
      var is_wireguard = this.is_wireguard === true ? 1 : 0;
      var is_automation = this.is_automation === true ? 1 : 0;
      var is_shadowsocks = this.is_shadowsocks === true ? 1 : 0;
      var is_v2ray = this.is_v2ray === true ? 1 : 0;

      //loader.show();
      rootObject.loading = true;
      const form = new FormData();
      form.append("server_id", this.id);
      form.append("server_ip", this.server_ip);
      form.append("server_password", this.server_password);
      form.append("auth_server_ip", this.auth_server_ip);
      form.append("auth_server_password", this.auth_server_password);
      form.append("v2ray_port", this.v2ray_port);
      form.append("is_openvpn", is_openvpn);
      form.append("is_wireguard", is_wireguard);
      form.append("is_automation", is_automation);
      form.append("is_shadowsocks", is_shadowsocks);
      form.append("is_v2ray", is_v2ray);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("ovpn-provisioning", form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.output = response.data;
          rootObject.loading = false;

          if (is_wireguard == 1) {
            if (response.data.wireguardconfig) {
              rootObject.generateDownloadFile(
                response.data.wireguardconfig,
                "wireguard",
                "wgconfig"
              );
            }
          }

          loader.hide();
        })
        .catch(function (error) {
          rootObject.loading = false;
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          rootObject.alert_message = alertmessage;
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    generateDownloadFile(result, protocolType, name) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(result)
      );
      if (protocolType == "openvpn") {
        element.setAttribute("download", name + ".ovpn");
      } else {
        element.setAttribute("download", name + ".conf");
      }
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

    onFileSelected(event) {
      this.wireguard_file = event.target.files[0];
    },

    fromValidation() {
      var has_error = false;
      if (!this.server_password) {
        this.error_server_password = "此字段是必需的";
        has_error = true;
      }

      if (!this.server_ip) {
        this.error_server_ip = "此字段是必需的";
        has_error = true;
      }
      if (this.is_openvpn) {
        if (!this.auth_server_ip) {
          this.error_auth_server_ip = "此字段是必需的";
          has_error = true;
        }

        if (!this.auth_server_password) {
          this.error_auth_server_password = "此字段是必需的";
          has_error = true;
        }
      }

      if (this.is_shadowsocks || this.is_v2ray) {
        if (!this.v2ray_port) {
          this.error_v2ray_port = "This field is required";
          has_error = true;
        }
      }

      if (
        this.is_openvpn == false &&
        this.is_wireguard == false &&
        this.is_shadowsocks == false &&
        this.is_v2ray == false
      ) {
        this.error_protocol_selection = "Select atleast one protocol";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.success {
  color: green;
}
.error {
  color: orangered;
}
pre {
  height: auto;
  max-height: 70vh;
  overflow: auto;
  background-color: #eeeeee;
  word-break: normal !important;
  word-wrap: normal !important;
  white-space: pre !important;
}
.alert > .start-icon {
  margin-right: 0;
  min-width: 20px;
  text-align: center;
}

.alert > .start-icon {
  margin-right: 5px;
}

.greencross {
  font-size: 18px;
  color: #25ff0b;
  text-shadow: none;
}

.alert-simple.alert-success {
  border: 1px solid rgba(36, 241, 6, 0.46);
  background-color: rgba(7, 149, 66, 0.12156862745098039);
  box-shadow: 0px 0px 2px #259c08;
  color: #0ad406;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}
.alert-success:hover {
  background-color: rgba(7, 149, 66, 0.35);
  transition: 0.5s;
}
.alert-simple.alert-info {
  border: 1px solid rgba(6, 44, 241, 0.46);
  background-color: rgba(7, 73, 149, 0.12156862745098039);
  box-shadow: 0px 0px 2px #0396ff;
  color: #0396ff;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}

.alert-info:hover {
  background-color: rgba(7, 73, 149, 0.35);
  transition: 0.5s;
}

.blue-cross {
  font-size: 18px;
  color: #0bd2ff;
  text-shadow: none;
}

.alert-simple.alert-warning {
  border: 1px solid rgba(241, 142, 6, 0.81);
  background-color: rgba(220, 128, 1, 0.16);
  box-shadow: 0px 0px 2px #ffb103;
  color: #ffb103;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}

.alert-warning:hover {
  background-color: rgba(220, 128, 1, 0.33);
  transition: 0.5s;
}

.warning {
  font-size: 18px;
  color: #ffb40b;
  text-shadow: none;
}

.alert:before {
  content: "";
  position: absolute;
  width: 0;
  height: calc(100% - 44px);
  border-left: 1px solid;
  border-right: 2px solid;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 20px;
}

.fa-times {
  -webkit-animation: blink-1 2s infinite both;
  animation: blink-1 2s infinite both;
}

/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
</style>
