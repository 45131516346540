<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>安装或修复 VPN 身份验证服务器</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> 标记为必填字段</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <form role="form">
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">要求（提供）的信息</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >服务器IP <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="server_ip"
                      v-model="server_ip"
                      id="server_ip"
                      class="form-control input-sm"
                      value=""
                      placeholder="服务器IP"
                      type="text"
                      disabled
                    />
                    <label v-if="error_server_ip" class="error" for="name">{{
                      error_server_ip
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >服务器密码<span class="mandatory_horizontal"
                      >*</span
                    ></label
                  >
                  <div class="col-md-8">
                    <input
                      name="server_password"
                      id="server_password"
                      class="form-control input-sm"
                      v-model="server_password"
                      value=""
                      placeholder="服务器密码"
                      type="text"
                    />
                    <label
                      v-if="error_server_password"
                      class="error"
                      for="name"
                      >{{ error_server_password }}</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >认证数据库主机
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="auth_db_host"
                      v-model="auth_db_host"
                      id="auth_db_host"
                      class="form-control input-sm"
                      value=""
                      placeholder="验证服务器 SSH IP(Ex:000.000.000.000)"
                      type="text"
                    />
                    <label v-if="error_auth_db_host" class="error" for="name">{{
                      error_auth_db_host
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >认证数据库端口<span class="mandatory_horizontal"
                      >*</span
                    ></label
                  >
                  <div class="col-md-8">
                    <input
                      name="auth_db_port"
                      id="auth_db_port"
                      class="form-control input-sm"
                      v-model="auth_db_port"
                      value=""
                      placeholder="Enter Auth Server Port"
                      type="text"
                    />
                    <label v-if="error_auth_db_port" class="error" for="name">{{
                      error_auth_db_port
                    }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >验证数据库用户<span class="mandatory_horizontal"
                      >*</span
                    ></label
                  >
                  <div class="col-md-8">
                    <input
                      name="auth_db_user"
                      id="auth_db_user"
                      class="form-control input-sm"
                      v-model="auth_db_user"
                      value=""
                      placeholder="验证数据库用户"
                      type="text"
                    />
                    <label v-if="error_auth_db_user" class="error" for="name">{{
                      error_auth_db_user
                    }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >认证数据库密码<span class="mandatory_horizontal"
                      >*</span
                    ></label
                  >
                  <div class="col-md-8">
                    <input
                      name="auth_db_pass"
                      id="auth_db_pass"
                      class="form-control input-sm"
                      v-model="auth_db_pass"
                      value=""
                      placeholder="认证数据库密码"
                      type="text"
                    />
                    <label v-if="error_auth_db_pass" class="error" for="name">{{
                      error_auth_db_pass
                    }}</label>
                  </div>
                </div>

                <div style="margin-top: 25px" class="form-group">
                  <div class="col-md-12 btnCenter">
                    <div v-if="!loading">
                      <a
                        @click="formSubmit"
                        class="btn btn-submit btn-submit-big"
                      >
                        {{ vpn_status == 3 ? "安装" : "维修" }}
                      </a>
                    </div>
                    <div v-else>
                      <span
                        style="
                          font-weight: 700;
                          font-size: 14px;
                          color: orangered;
                          margin-bottom: 10px;
                        "
                        >服务器配置开始。请稍等.
                        <img :src="loaderGif" alt="Loading" />
                      </span>
                      <div
                        class="alert text-center alert-warning alert-dismissible text-left font__family-montserrat font__size-16 font__weight-light brk-library-rendered rendered show"
                        role="alert"
                        data-brk-library="component__alert"
                      >
                        <i
                          class="start-icon fa fa-info-circle faa-shake animated"
                        ></i>
                        <strong class="font__weight-semibold">注意力!</strong>
                        服务器配置时请不要重新加载.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">安慰</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <pre
                  :class="output.installation_status == 1 ? 'success' : 'error'"
                >
                   <div class="success" v-if="loading">
                    加工 ....
                  </div>
                  {{ output.auth_server_log }}
                </pre>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "VpnAuthServerInstaller",
  data() {
    return {
      loaderGif: "/assets/img/provisoning.gif",
      value: null,
      id: "",
      server_ip: "",
      server_password: "",
      auth_db_host: "",
      auth_db_port: "",
      auth_db_user: "",
      auth_db_pass: "",
      vpn_status: "",
      output: "",
      loading: false,
      isEditing: false,
      alert_message: "",
      LabelFormName: "Add New",
      error_server_password: false,
      error_server_ip: false,
      error_auth_db_host: false,
      error_auth_db_port: false,
      error_auth_db_user: false,
      error_auth_db_pass: false,
    };
  },
  mounted: function () {
    $(".select2").select2();
  },
  created() {
    loader.show();
    var rootObject = this;
    if (this.$route.params.id) {
      rootObject.isEditing = true;
      rootObject.id = this.$route.params.id;
      rootObject.getEditData();
    }
  },
  methods: {
    labelWithCity({ label, city }) {
      return `${label} — [${city}]`;
    },
    getEditData() {
      loader.show();
      let rootObject = this;
      rootObject.LabelFormName = "Edit";
      axios
        .get("radius-auth-server/" + rootObject.id)
        .then(function (response) {
          rootObject.server_ip = response.data.ip;
          rootObject.vpn_status = response.data.vpn_status;

          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_server_password = false;
          rootObject.error_ip = false;
          rootObject.error_auth_db_host = false;
          rootObject.error_auth_db_port = false;
          rootObject.error_auth_db_user = false;
          rootObject.error_auth_db_pass = false;
        }, 5000);
        return 0;
      }

      //loader.show();
      rootObject.loading = true;
      const form = new FormData();
      form.append("server_id", this.id);
      form.append("server_ip", this.server_ip);
      form.append("server_password", this.server_password);
      form.append("auth_db_host", this.auth_db_host);
      form.append("auth_db_port", this.auth_db_port);
      form.append("auth_db_user", this.auth_db_user);
      form.append("auth_db_pass", this.auth_db_pass);
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("radius-server-provisioning", form, config)
        .then(function (response) {
          console.log(response);
          rootObject.output = response.data;
          rootObject.loading = false;
          rootObject.server_password = "";
          rootObject.auth_db_host = "";
          rootObject.auth_db_port = "";
          rootObject.auth_db_user = "";
          rootObject.auth_db_pass = "";
          loader.hide();
        })
        .catch(function (error) {
          console.log(error);
          let alertmessage = "";
          rootObject.loading = false;
          // loader.hide();
          rootObject.com_errorHandel(error);
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          rootObject.alert_message = alertmessage;
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    onFileSelected(event) {
      //console.log(event.target.files[0]);
      this.wireguard_file = event.target.files[0];
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.server_password) {
        this.error_server_password = "此字段是必需的";
        has_error = true;
      }

      if (!this.server_ip) {
        this.error_server_ip = "此字段是必需的";
        has_error = true;
      }

      if (!this.auth_db_host) {
        this.error_auth_db_host = "此字段是必需的";
        has_error = true;
      }

      if (!this.auth_db_port) {
        this.error_auth_db_port = "此字段是必需的";
        has_error = true;
      }

      if (!this.auth_db_user) {
        this.error_auth_db_user = "此字段是必需的";
        has_error = true;
      }
      if (!this.auth_db_pass) {
        this.error_auth_db_pass = "此字段是必需的";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.success {
  color: green;
}
.error {
  color: orangered;
}
pre {
  height: auto;
  max-height: 70vh;
  overflow: auto;
  background-color: #eeeeee;
  word-break: normal !important;
  word-wrap: normal !important;
  white-space: pre !important;
}

.alert > .start-icon {
  margin-right: 0;
  min-width: 20px;
  text-align: center;
}

.alert > .start-icon {
  margin-right: 5px;
}

.greencross {
  font-size: 18px;
  color: #25ff0b;
  text-shadow: none;
}

.alert-simple.alert-success {
  border: 1px solid rgba(36, 241, 6, 0.46);
  background-color: rgba(7, 149, 66, 0.12156862745098039);
  box-shadow: 0px 0px 2px #259c08;
  color: #0ad406;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}
.alert-success:hover {
  background-color: rgba(7, 149, 66, 0.35);
  transition: 0.5s;
}
.alert-simple.alert-info {
  border: 1px solid rgba(6, 44, 241, 0.46);
  background-color: rgba(7, 73, 149, 0.12156862745098039);
  box-shadow: 0px 0px 2px #0396ff;
  color: #0396ff;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}

.alert-info:hover {
  background-color: rgba(7, 73, 149, 0.35);
  transition: 0.5s;
}

.blue-cross {
  font-size: 18px;
  color: #0bd2ff;
  text-shadow: none;
}

.alert-simple.alert-warning {
  border: 1px solid rgba(241, 142, 6, 0.81);
  background-color: rgba(220, 128, 1, 0.16);
  box-shadow: 0px 0px 2px #ffb103;
  color: #ffb103;
  text-shadow: 2px 1px #00040a;
  transition: 0.5s;
  cursor: pointer;
}

.alert-warning:hover {
  background-color: rgba(220, 128, 1, 0.33);
  transition: 0.5s;
}

.warning {
  font-size: 18px;
  color: #ffb40b;
  text-shadow: none;
}

.alert:before {
  content: "";
  position: absolute;
  width: 0;
  height: calc(100% - 44px);
  border-left: 1px solid;
  border-right: 2px solid;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 20px;
}

.fa-times {
  -webkit-animation: blink-1 2s infinite both;
  animation: blink-1 2s infinite both;
}

/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
</style>
