<template>
  	<EditSinglePin />
</template>

<script>

// @ is an alias to /src
import EditSinglePin from "@/components/EditSinglePin.vue";

export default {
  name: "EditSinglePins",
  components: {
    EditSinglePin,
  },
};
</script>
