<template>
  	<IpList />
</template>

<script>

// @ is an alias to /src
import IpList from "@/components/IpLists/IpLists.vue";

export default {
  name: "IpLists",
  components: {
	  IpList,
  },
};
</script>
