<template>
  <body
    class="hold-transition login-page"
    :style="'background-color:' + panelData.login_bg_color"
  >
    <div id="result" v-if="!loadNow">
      <p style="text-align: center">
        <br /><br /><br />
        Loading . . .
      </p>
    </div>
    <div v-if="loadNow">
      <h3 class="loginBillName" :style="'color:' + panelData.com_name_color">
        {{ panelData.com_name }}
      </h3>
      <div class="login-box">
        <div class="col-sm-6 col-sm-offset-3">
          <div class="modal-content">
            <div
              class="modal-header"
              :style="
                'padding: 0px; background: ' + panelData.login_header_color
              "
            >
              <h4>Password Reset</h4>
            </div>
            <div
              class="modal-body"
              :style="'border:2px solid ' + panelData.login_header_color"
            >
              <div class="row">
                <div
                  class="col-sm-6 col-sm-offset-3"
                  id="login_form"
                  style="text-align: center; padding: 20px 0px 50px 0px"
                  align="center"
                >
                  <form role="form" @submit="resetPassword">
                    <div align="center">
                      <img class="img_logo" id="img_logo" :src="logoImg" />
                    </div>

                    <div class="alert alert-info" v-if="info_message">
                      {{ info_message }}
                    </div>

                    <div v-if="token_verified">
                      <div class="form-group has-feedback">
                        <input
                          type="password"
                          id="password"
                          v-model="password"
                          style="padding: 5px 10px; height: 30px"
                          name="userPassword"
                          class="form-control input-sm"
                          placeholder="Enter New Password"
                        />
                        <span
                          class="glyphicon glyphicon-lock form-control-feedback"
                        ></span>
                      </div>
                      <div class="form-group has-feedback">
                        <input
                          type="password"
                          style="padding: 5px 10px; height: 30px"
                          name="password_confirmation"
                          id="password_confirmation"
                          v-model="password_confirmation"
                          class="form-control input-sm"
                          placeholder="Confirm Password"
                        />
                        <span
                          class="glyphicon glyphicon-user form-control-feedback"
                        ></span>
                      </div>
                    </div>
                    <div v-else>
                      <div class="form-group has-feedback">
                        <input
                          type="text"
                          style="padding: 5px 10px; height: 30px"
                          name="email"
                          id="email"
                          v-model="email"
                          class="form-control input-sm"
                          placeholder="Enter Email Address"
                        />
                        <span
                          class="glyphicon glyphicon-envelope form-control-feedback"
                        ></span>
                      </div>
                    </div>
                    <div class="form-group"><br /></div>
                    <button
                      type="submit"
                      name="commit"
                      value="Submit"
                      class="btn btn-info btn-login btn-flat"
                      :style="
                        'background-color: ' +
                        panelData.login_header_color +
                        '; border:none;'
                      "
                    >
                      {{ button_name }}
                    </button>
                  </form>
                </div>
                <div class="col-sm-6 col-sm-offset-3" align="center">
                  <span style="text-align: right">
                    <router-link
                      :to="{ name: 'loginView' }"
                      style="color: rgb(184, 0, 0)"
                    >
                      Login Now
                    </router-link>
                  </span>
                  <br /><br /><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-12 copyright-text"
        style="
          margin: 15px 0;
          font: 300 12px 'Roboto', sans-serif;
          text-transform: uppercase;
          letter-spacing: 2px;
          text-align: center;
        "
      >
        Copyright © 2023 - {{ new Date().getFullYear() }} | Powered by
        <strong> {{ panelData.com_name }}</strong>
      </div>
    </div>
  </body>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "PasswordReset",
  data() {
    return {
      token_verified: false,
      button_name: "Submit",
      email: "",
      password: "",
      password_confirmation: "",
      info_message: "",
      api_public_path: process.env.VUE_APP_APIADMINPUBLIC,
      loadNow: false,
      support_admin_id: "",
      logoImg: "/assets/img/nologo.png",
      token: "",
      id: "",
    };
  },
  created() {
    var rootObject = this;
    //Get Panel Configurations
    axios
      .get("panel-setting")
      .then(function (response) {
        //console.log(response.data);
        if (response.data.settings == null) {
          rootObject.com_pageNotFound();
        } else {
          let panelparam = {
            panel_settings: response.data.settings,
          };
          rootObject.com_setPanelData(panelparam);
          var logoImgURL =
            rootObject.api_public_path +
            "uploads/images/" +
            response.data.settings.logo_images;
          rootObject.checkIfImageExists(logoImgURL, (exists) => {
            if (exists) {
              rootObject.logoImg = logoImgURL;
            }
          });

          if (response.data.settings.favicon) {
            $("#favicon").attr(
              "href",
              rootObject.api_public_path +
                "uploads/images/" +
                response.data.settings.favicon
            );
          } else {
            $("#favicon").attr("href", "/assets/img/logo.png");
          }
          window.document.title =
            "管理面板 - " + response.data.settings.com_name.toUpperCase();
          rootObject.loadNow = true;
          rootObject.getRequestedDataDecrypt();
        }
      })
      .catch(function (error) {
        rootObject.com_pageNotFound();
      });
  },
  methods: {
    checkIfImageExists(url, callback) {
      const img = new Image();
      img.src = url;
      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };
        img.onerror = () => {
          callback(false);
        };
      }
    },

    getRequestedDataDecrypt() {
      var urlParams = new URLSearchParams(window.location.search);
      var pass_reset_token = "";
      if (urlParams.has("pass_reset_token")) {
        pass_reset_token = urlParams.get("pass_reset_token");
        pass_reset_token = pass_reset_token.split(" ").join("+");
        //console.log(pass_reset_token);
        var decodedString = this.com_DecryptString(pass_reset_token);
        var decodedData = JSON.parse(decodedString);
        this.id = decodedData.id;
        this.token = decodedData.verify_token;

        var url = "reset_password_validate";
        var param = {
          id: this.id,
          token: this.token,
        };
        var addtionalData = {
          doValidationToken: true,
        };
        this.callApiWithAxios(url, param, addtionalData);
        //console.log(decodedData);
      }
    },

    resetPassword(e) {
      e.preventDefault();
      if (this.token_verified) {
        if (!this.password) {
          toastr.error("Password is Mandatory", "Required !!!");
          $("#password").focus();
          return 0;
        }
        if (!this.password_confirmation) {
          toastr.error("Confirm Password is Mandatory", "Required !!!");
          $("#password_confirmation").focus();
          return 0;
        }
        if (this.password != this.password_confirmation) {
          toastr.error("Confirm Password does not match", "Alert !!!");
          $("#password_confirmation").focus();
          return 0;
        }
        var url = "save_reset_password";
        var param = {
          id: this.id,
          token: this.token,
          password: this.password,
          password_confirmation: this.password_confirmation,
        };
      } else {
        if (!this.email) {
          toastr.error("Email is Mandatory", "Required !!!");
          $("#email").focus();
          return 0;
        }
        var url = "reset_password_request";
        var param = {
          email: this.email,
        };
      }
      this.callApiWithAxios(url, param);
    },

    callApiWithAxios(url, param, addtionalData = []) {
      var rootObject = this;
      var panel = localStorage.getItem("panel");
      loader.show();
      axios
        .post(url, param)
        .then(function (response) {
          //console.log(response);
          loader.hide();
          rootObject.info_message = response.data.message;
          if (addtionalData.doValidationToken == true) {
            if (response.data.validate == true) {
              rootObject.token_verified = true;
              rootObject.button_name = "Reset Now";
            }
          }
          rootObject.password = "";
          rootObject.password_confirmation = "";
          setTimeout(function () {
            rootObject.info_message = "";
          }, 5000);
        })
        .catch(function (error) {
          let alertmessage = "";
          if (error.response.data) {
            for (var prop in error.response.data) {
              alertmessage += " " + error.response.data[prop];
            }
          }
          loader.hide();
          //console.log(alertmessage);
          rootObject.info_message = alertmessage;
          setTimeout(function () {
            rootObject.info_message = "";
          }, 3000);
        });
    },
  },
  computed: {
    ...mapGetters({
      panelData: "getPanelData",
    }),
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
