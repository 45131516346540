import { render, staticRenderFns } from "./AddPanelSetting.vue?vue&type=template&id=482015f2&scoped=true"
import script from "./AddPanelSetting.vue?vue&type=script&lang=js"
export * from "./AddPanelSetting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482015f2",
  null
  
)

export default component.exports