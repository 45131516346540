<template>
  	<EditIp />
</template>

<script>

// @ is an alias to /src
import EditIp from "@/components/IpLists/IpForm.vue";

export default {
  name: "EditIps",
  components: {
    EditIp,
  },
};
</script>
