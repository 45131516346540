<template>
  	<IpBundlAdd />
</template>

<script>

// @ is an alias to /src
import IpBundlAdd from "@/components/IpBundle/IpBundleForm.vue";

export default {
  name: "IpBundlAdds",
  components: {
	  IpBundlAdd
  },
};
</script>
