<template>
  <footer class="main-footer">
    <div class="footer-div">
      <span></span>
      版权 &copy; 2022 - {{ new Date().getFullYear() }} | 供电
      {{ panelData.com_name }}.
      <span
        class="glyphicon glyphicon-info-sign"
        data-toggle="modal"
        data-target="#myModal"
      ></span>
      <b> v1.0</b>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AppFooter",
  computed: {
    ...mapGetters({
      panelData: "getPanelData",
    }),
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
