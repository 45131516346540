<template>
  	<EditServer />
</template>

<script>

// @ is an alias to /src
import EditServer from "@/components/ServerForm.vue";

export default {
  name: "EditServers",
  components: {
    EditServer,
  },
};
</script>
